<template>
  <div class="about">
    <web-header active-url="/about"></web-header>
    <div class="about-one-wrap" ref="page2">
      <div class="about-one-wrap-content">
        <div class="f-wrap">
          <div class="f-wrap-header">
            <div class="f-wrap-header-title">
              <div class="f-wrap-header-title-en">ABOUT US</div>
              <div class="f-wrap-header-title-cn">NFI 简介</div>
            </div>
            <a href="/detail/47" target="_blank">View More</a>
          </div>
          <div class="f-wrap-content">
            <span class="f-wrap-content-introduce">
              上海新金融研究院（Shanghai Finance Institute，SFI）是一家金融专业智库，致力于新金融和资产管理领域的政策研究。<br>
              研究院成立于2011年7月14日，由中国金融四十人论坛（China Finance 40 Forum,CF40）举办，与上海市黄浦区人民政府战略合作。
              研究院的业务主管单位是上海市金融工作局；登记管理机关是上海市民政局。
            </span>
            <div class="f-wrap-content-list">
              <div class="f-wrap-content-list-icon">
                <img src="~@/assets/about_left.png" />
              </div>
              <div class="f-wrap-content-list-icon">
                <img src="~@/assets/about_right.png" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="about-two-wrap" ref="page3">
      <div class="about-two-wrap-content">
        <div class="f-wrap">
          <div class="f-wrap-header">
            <div class="f-wrap-header-title">
              <div class="f-wrap-header-title-en">MEMBERS</div>
              <div class="f-wrap-header-title-cn">组织架构</div>
            </div>
            <a href="/detail/48" target="_blank">View More</a>
          </div>
          <div class="f-wrap-content">
            <div class="f-wrap-content-left">
              <el-tree
                  :data="organizeList"
                  :props="{
                    label: 'dict_label',
                    children: 'children'
                  }"
                  empty-text="暂无组织组织架构数据"
                  accordion
                  @node-click="handleNodeClick"
              >
                <span class="custom-tree-node" slot-scope="{ node, data }">
                  <i :class="node.level == 1 ? 'fa fa-circle-thin' : ''"></i>
                  <span>{{ data.dict_label }}</span>
                  <i :class="node.level == 1 ? 'fa fa-angle-right' : ''"></i>
                </span>
              </el-tree>
            </div>
            <div class="f-wrap-content-right">
              <div class="f-wrap-content-right-item" v-for="(item, index) in personList" :key="index">
                <div class="icon" v-if="item.litpic && item.litpic.img">
                  <img :src="item.litpic.img" />
                </div>
                <div class="name">{{ item.title }}</div>
                <div class="desc">{{ item.duties }}</div>
              </div>
              <el-empty description="暂无组织架构信息" v-if="!personList || personList.length == 0"></el-empty>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="about-three-wrap" ref="page4">
      <div class="about-three-wrap-content">
        <div class="f-wrap">
          <div class="f-wrap-header">
            <div class="f-wrap-header-title">
              <div class="f-wrap-header-title-en">MEMBER</div>
              <div class="f-wrap-header-title-cn">理事单位</div>
            </div>
          </div>
          <div class="f-wrap-content">
            <div class="f-wrap-content-list">
              <div class="f-wrap-content-list-item" v-for="(item, index) in companyList" :key="index">
                <div class="icon">
                  <img :src="item.image.img" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <web-navigation></web-navigation>
    <web-footer></web-footer>
    <web-right-menu class="right-menu"></web-right-menu>
  </div>
</template>

<script>
import WebHeader from "@/components/webHeader";
import WebFooter from "@/components/webFooter";
import WebRightMenu from "@/components/webRightMenu";
import WebNavigation from "@/components/webNavigation";

import { articleList, dictionaryList } from "@/api";
export default {
  name: "About",
  components: {
    WebHeader,
    WebFooter,
    WebRightMenu,
    WebNavigation
  },
  data() {
    return {
      // 组织架构
      organizeList: [],
      // 默认选中的值
      checkOrganize: "",
      // 选中的组织架构人员列表
      personList: [],
      // 理事单位
      companyList: []
    }
  },
  async created() {
    // 判断存在锚文本，并定位到锚文本
    if (this.$route.hash) {
      this.scrollToAnchor(this.$route.hash);
    }
    this.companyList = await this.getArticleList(39, 22, "image_id:0", "image_id:>", "sort", "desc");
    await this.getOrganizeList();
    if (this.checkOrganize) {
      this.handleNodeClick({dict_value: this.checkOrganize}, { level: 1 });
    }
  },
  mounted() {
    // 判断存在锚文本，并定位到锚文本
    if (this.$route.hash) {
      this.scrollToAnchor(this.$route.hash);
    }
  },
  methods: {
    // 树形菜单点击选择
    async handleNodeClick(row, node) {
      let search = "";
      let searchFields = "";
      if (node.level == 1) {
        search = "linkage_one:" + row.dict_value;
        searchFields = "linkage_one:=";
      } else if (node.level == 2) {
        search = "linkage_two:" + row.dict_value;
        searchFields = "linkage_two:=";
      } else if (node.level == 3) {
        search = "linkage_id:" + row.dict_value;
        searchFields = "linkage_id:=";
      }
      this.personList = await this.getArticleList(40, 100, search, searchFields, "sort", "desc");
    },
    // 定位到锚文本
    scrollToAnchor(e) {
      if (e && e.indexOf("#") > -1) {
        const element = this.$refs[e.replace(new RegExp("#", 'g'), '')];
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
        }
      }
    },
    // 获取组织架构
    async getOrganizeList() {
      let param = {
        orderBy: "dict_sort",
        sortedBy: "desc",
        search: "dict_type:organizational_structure|dict_sort:0",
        searchFields: "dict_type:=|dict_sort:<>"
      };
      let res = await dictionaryList(param);
      if (res && res.code == 200) {
        this.organizeList = this.handleTree(res.data.list, "id", "parent_id");
      }
      if (this.organizeList && this.organizeList.length > 0) {
        this.checkOrganize = this.organizeList[0]['dict_value'];
      }
    },
    // 获取文章列表
    async getArticleList(categoryId, size, search, searchFields, order, sort) {
      let param = {
        pageSize: size,
        currentPage: 1,
        orderBy: order,
        sortedBy: sort,
        search: search,
        searchFields: searchFields
      };
      let arr = [];
      let res = await articleList(param, categoryId);
      if (res && res.code == 200) {
        arr = res.data.list;
      }
      return arr;
    },
  }
}
</script>
<style lang="scss" scoped>
.about {
  &-one-wrap {
    width: 100%;
    &-content {
      width: 100%;
      height: 100%;
      background: url("~@/assets/about_one_bg.jpg") no-repeat;
      background-size: cover;
      .f-wrap {
        padding-top: 130px;
        padding-bottom: 130px;
        &-header {
          border: none;
          margin-bottom: 20px;
          &:before {
            width: 0;
            height: 0;
            display: none;
          }
          &-title {
            color: #ffffff;
            &-en {
              color: #ffffff;
              opacity: 0.2;
            }
          }
          a {
            color: #FFFFFF;
            background: url("~@/assets/more_white.png") right no-repeat;
          }
        }
        &-content {
          &-introduce {
            width: 580px;
            font-size: 16px;
            line-height: 22px;
            color: #ffffff;
            padding-left: 10px;
            padding-right: 15px;
            display: block;
          }
          &-list {
            margin-top: 73px;
            display: flex;
            justify-content: space-between;
            &-icon {
              width: 543px;
              height: 406px;
              border: 4px solid #fff;
              overflow: hidden;
              border-radius: 8px;
              display: flex;
              justify-content: center;
              align-items: center;
              img {
                width: 100%;
                height: auto;
              }
            }
          }
        }
      }
    }
  }
  &-two-wrap {
    width: 100%;
    &-content {
      width: 100%;
      height: 100%;
      background: url("~@/assets/about_two_bg.jpg") no-repeat;
      background-size: cover;
      .f-wrap {
        padding-top: 130px;
        padding-bottom: 130px;
        &-header {
          border: none;
          margin-bottom: 20px;
          &:before {
            width: 0;
            height: 0;
            display: none;
          }
        }
        &-content {
          display: flex;
          &-left {
            min-width: 250px;
            ::v-deep {
              .el-tree {
                background: none;
                .el-tree-node {
                  padding-top: 20px;
                  font-size: 16px;
                  color: #333333;
                }
                .el-tree-node.is-expanded>.el-tree-node__children {
                  padding-left: 10px;
                }
                .custom-tree-node {
                  font-size: 16px;
                  .fa-circle-thin {
                    margin-right: 10px;
                    color: #004898;
                  }
                  .fa-angle-right {
                    margin-left: 10px;
                  }
                }
                .el-tree-node__expand-icon.is-leaf,
                .el-tree-node__expand-icon{
                  display: none;
                }
                .el-tree-node__content:hover,
                .el-upload-list__item:hover {
                  background-color: inherit;
                  color: #004898;
                }
                .el-tree-node__content.is-current {
                  background-color: inherit;
                  color: #004898;
                }
                .el-tree-node:focus > .el-tree-node__content {
                  background-color: inherit;
                  color: #004898;
                }
              }
            }
          }
          &-right {
            min-height: 580px;
            flex: 1;
            display: flex;
            flex-wrap: wrap;
            &-item {
              width: calc(20% - 57px);
              height: 290px;
              padding-right: 71px;
              &:nth-child(5n) {
                width: calc(20% - 57px);
                padding-right: 0;
              }
              .icon {
                width: 100%;
                height: 180px;
                margin-bottom: 15px;
                cursor: pointer;
                img {
                  width: 100% !important;
                  height: 100%;
                  border-radius: 5px;
                }
              }
              .name {
                cursor: pointer;
                font-size: 18px;
                text-align: center;
                color: #333333;
              }
              .desc {
                cursor: pointer;
                font-size: 14px;
                color: #707070;
                text-align: center;
                margin-top: 3px;
                display: block;
              }
            }
            ::v-deep .el-empty {
              flex: 1;
            }
          }
        }
      }
    }
  }
  &-three-wrap {
    width: 100%;
    &-content {
      width: 100%;
      height: 100%;
      background: url("~@/assets/about_three_bg.jpg") no-repeat;
      background-size: cover;
      .f-wrap {
        padding-top: 130px;
        padding-bottom: 130px;
        &-header {
          border: none;
          margin-bottom: 18px;
        }
        &-content {
          min-height: 500px;
          &-list {
            display: flex;
            flex-wrap: wrap;
            &-item {
              position: relative;
              width: 172px;
              height: 86px;
              display: flex;
              justify-content: center;
              align-items: center;
              &:first-child {
                margin-left: 198px;
              }
              &:nth-child(6) {
                margin-left: 162px;
              }
              &:nth-child(11) {
                margin-left: 299px;
              }
              &:nth-child(16) {
                margin-left: 90px;
              }
              &:nth-child(21) {
                margin-left: 227px;
              }
              .icon {
                img {
                  display: inline-block;
                  max-height: 77px;
                  width: auto;
                  max-width: 154px;
                  height: auto;
                  transition: transform 0.3s ease;
                }
              }
              &:hover {
                &:before {
                  opacity: 1;
                  visibility: visible;
                  content: '';
                  position: absolute;
                  left: 50%;
                  top: 50%;
                  width: 100%;
                  height: 100%;
                  background-color: #fff;
                  box-shadow: 0px 1px 17.64px 0.36px rgba(0, 0, 0, 0.16);
                  transition-duration: .5s;
                  transform: translate(-50%, -50%) skewX(-23deg);
                }
                .icon {
                  img {
                    transform: scale(1.1);
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
/* 针对平板 */
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .about {
    &-one-wrap {
      &-content {
        .f-wrap {
          padding-top: 50px;
          padding-bottom: 50px;
          &-content {
            &-introduce {
              width: auto;
              max-width: 100%;
              font-size: 26px;
              line-height: 40px;
            }
            &-list {
              flex-direction: column;
              align-items: center;
              padding: 0 15px 0 10px;
              &-icon {
                width: 100%;
                height: auto;
                &:nth-child(2) {
                  margin-top: 30px;
                }
              }
            }
          }
        }
      }
    }
    &-two-wrap {
      &-content {
        background: none;
        .f-wrap {
          padding-top: 50px;
          padding-bottom: 50px;
          &-content {
            flex-direction: column;
            &-right {
              margin-top: 30px;
              &-item {
                width: calc(50% - 26px);
                height: auto;
                padding-right: 51px;
                margin-bottom: 15px;
                &:nth-child(2n) {
                  width: calc(50% - 26px);
                  padding-right: 0;
                }
                &:nth-child(5n) {
                  width: calc(50% - 26px);
                  padding-right: 51px;
                }
                .icon {
                  height: auto;
                }
                .name {
                  font-size: 26px;
                }
                .desc {
                  font-size: 18px;
                }
              }
            }
          }
        }
      }
    }
    &-three-wrap {
      &-content {
        background: #f7f7f7;
        .f-wrap {
          &-content {
            min-height: auto;
            &-list {
              &-item {
                width: calc(25% - 10px);
                height: auto;
                margin-right: 10px;
                margin-bottom: 10px;
                &:first-child {
                  margin-left: 0;
                }
                &:nth-child(6) {
                  margin-left: 0;
                }
                &:nth-child(11) {
                  margin-left: 0;
                }
                &:nth-child(5n) {
                  margin-left: 0;
                }
                &:nth-child(16) {
                  margin-left: 0;
                }
                &:nth-child(21) {
                  margin-left: 0;
                }
                .icon {
                  img {
                    max-width: 100%;
                    min-height: auto;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
/* 针对手机 */
@media screen and (max-width: 767px) {
  .about {
    &-one-wrap {
      &-content {
        .f-wrap {
          padding-top: 50px;
          padding-bottom: 50px;
          &-content {
            &-introduce {
              width: auto;
              max-width: 100%;
              font-size: 18px;
              line-height: 28px;
            }
            &-list {
              margin-top: 35px;
              flex-direction: column;
              align-items: center;
              padding: 0 15px 0 10px;
              &-icon {
                width: 100%;
                height: auto;
                &:nth-child(2) {
                  margin-top: 15px;
                }
              }
            }
          }
        }
      }
    }
    &-two-wrap {
      &-content {
        background: none;
        .f-wrap {
          padding-top: 50px;
          padding-bottom: 50px;
          &-content {
            flex-direction: column;
            &-right {
              margin-top: 30px;
              &-item {
                width: calc(50% - 13px);
                height: auto;
                padding-right: 26px;
                margin-bottom: 15px;
                &:nth-child(2n) {
                  width: calc(50% - 13px);
                  padding-right: 0;
                }
                &:nth-child(5n) {
                  width: calc(50% - 13px);
                  padding-right: 26px;
                }
                .icon {
                  height: auto;
                }
              }
            }
          }
        }
      }
    }
    &-three-wrap {
      &-content {
        background: #f7f7f7;
        .f-wrap {
          padding-top: 60px;
          padding-bottom: 60px;
          &-content {
            min-height: auto;
            &-list {
              &-item {
                width: calc(25% - 10px);
                height: auto;
                margin-right: 10px;
                margin-bottom: 10px;
                &:first-child {
                  margin-left: 0;
                }
                &:nth-child(6) {
                  margin-left: 0;
                }
                &:nth-child(11) {
                  margin-left: 0;
                }
                &:nth-child(5n) {
                  margin-left: 0;
                }
                &:nth-child(16) {
                  margin-left: 0;
                }
                &:nth-child(21) {
                  margin-left: 0;
                }
                .icon {
                  img {
                    max-width: 100%;
                    min-height: auto;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>