var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"about"},[_c('web-header',{attrs:{"active-url":"/about"}}),_c('div',{ref:"page2",staticClass:"about-one-wrap"},[_vm._m(0)]),_c('div',{ref:"page3",staticClass:"about-two-wrap"},[_c('div',{staticClass:"about-two-wrap-content"},[_c('div',{staticClass:"f-wrap"},[_vm._m(1),_c('div',{staticClass:"f-wrap-content"},[_c('div',{staticClass:"f-wrap-content-left"},[_c('el-tree',{attrs:{"data":_vm.organizeList,"props":{
                  label: 'dict_label',
                  children: 'children'
                },"empty-text":"暂无组织组织架构数据","accordion":""},on:{"node-click":_vm.handleNodeClick},scopedSlots:_vm._u([{key:"default",fn:function({ node, data }){return _c('span',{staticClass:"custom-tree-node"},[_c('i',{class:node.level == 1 ? 'fa fa-circle-thin' : ''}),_c('span',[_vm._v(_vm._s(data.dict_label))]),_c('i',{class:node.level == 1 ? 'fa fa-angle-right' : ''})])}}])})],1),_c('div',{staticClass:"f-wrap-content-right"},[_vm._l((_vm.personList),function(item,index){return _c('div',{key:index,staticClass:"f-wrap-content-right-item"},[(item.litpic && item.litpic.img)?_c('div',{staticClass:"icon"},[_c('img',{attrs:{"src":item.litpic.img}})]):_vm._e(),_c('div',{staticClass:"name"},[_vm._v(_vm._s(item.title))]),_c('div',{staticClass:"desc"},[_vm._v(_vm._s(item.duties))])])}),(!_vm.personList || _vm.personList.length == 0)?_c('el-empty',{attrs:{"description":"暂无组织架构信息"}}):_vm._e()],2)])])])]),_c('div',{ref:"page4",staticClass:"about-three-wrap"},[_c('div',{staticClass:"about-three-wrap-content"},[_c('div',{staticClass:"f-wrap"},[_vm._m(2),_c('div',{staticClass:"f-wrap-content"},[_c('div',{staticClass:"f-wrap-content-list"},_vm._l((_vm.companyList),function(item,index){return _c('div',{key:index,staticClass:"f-wrap-content-list-item"},[_c('div',{staticClass:"icon"},[_c('img',{attrs:{"src":item.image.img}})])])}),0)])])])]),_c('web-navigation'),_c('web-footer'),_c('web-right-menu',{staticClass:"right-menu"})],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"about-one-wrap-content"},[_c('div',{staticClass:"f-wrap"},[_c('div',{staticClass:"f-wrap-header"},[_c('div',{staticClass:"f-wrap-header-title"},[_c('div',{staticClass:"f-wrap-header-title-en"},[_vm._v("ABOUT US")]),_c('div',{staticClass:"f-wrap-header-title-cn"},[_vm._v("NFI 简介")])]),_c('a',{attrs:{"href":"/detail/47","target":"_blank"}},[_vm._v("View More")])]),_c('div',{staticClass:"f-wrap-content"},[_c('span',{staticClass:"f-wrap-content-introduce"},[_vm._v(" 上海新金融研究院（Shanghai Finance Institute，SFI）是一家金融专业智库，致力于新金融和资产管理领域的政策研究。"),_c('br'),_vm._v(" 研究院成立于2011年7月14日，由中国金融四十人论坛（China Finance 40 Forum,CF40）举办，与上海市黄浦区人民政府战略合作。 研究院的业务主管单位是上海市金融工作局；登记管理机关是上海市民政局。 ")]),_c('div',{staticClass:"f-wrap-content-list"},[_c('div',{staticClass:"f-wrap-content-list-icon"},[_c('img',{attrs:{"src":require("@/assets/about_left.png")}})]),_c('div',{staticClass:"f-wrap-content-list-icon"},[_c('img',{attrs:{"src":require("@/assets/about_right.png")}})])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"f-wrap-header"},[_c('div',{staticClass:"f-wrap-header-title"},[_c('div',{staticClass:"f-wrap-header-title-en"},[_vm._v("MEMBERS")]),_c('div',{staticClass:"f-wrap-header-title-cn"},[_vm._v("组织架构")])]),_c('a',{attrs:{"href":"/detail/48","target":"_blank"}},[_vm._v("View More")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"f-wrap-header"},[_c('div',{staticClass:"f-wrap-header-title"},[_c('div',{staticClass:"f-wrap-header-title-en"},[_vm._v("MEMBER")]),_c('div',{staticClass:"f-wrap-header-title-cn"},[_vm._v("理事单位")])])])
}]

export { render, staticRenderFns }